import React from "react"
import { useApp } from "../../../hooks/useApp"

export const withRedPepperCatalogueEmbed = Component => ({
  name = "RedPepperCatalogueEmbed",
  catalogueId,
}) => {
  const { globalState } = useApp()
  const [{ activeCart }] = globalState
  
  Component.displayName = name
  return <Component catalogueId={catalogueId} activeCart={activeCart} />
}

import React from "react"
import { withRedPepperCatalogueEmbed } from "./withRedPepperCatalogueEmbed"
import { Script } from "gatsby"
import { Modal, ModalContentsWithLayout } from "../../Modal/Modal"
import { QuickViewContent } from "../../Product/QuickView/Content/QuickViewContent"

export const RedPepperCatalogueEmbed = withRedPepperCatalogueEmbed(
  ({ catalogueId, activeCart }) => {
    const handleClose = () => {      
      setQuickViewProduct(undefined)
    }

    const [quickViewProduct, setQuickViewProduct] = React.useState<
      string | undefined
    >(undefined)

    const quickViewProductParentHandle = React.useMemo(() => {
      if (!quickViewProduct) return
      function getParentHandle(handle) {
        const regex = /^(.*[a-z]\d+)/i;
        const match = handle.match(regex);
      
        return match ? match[0] : null;
      }
      return getParentHandle(quickViewProduct)
    }, [quickViewProduct])

    React.useEffect(() => {
      if (typeof window === "undefined") return
      window.addEventListener("message", event => {
        if (event.data.event === "pop-up" && !!event.data.productURL) {
          const url = new URL(event.data.productURL)
          const productHandle = url.pathname.split("/").pop()        
          setQuickViewProduct(productHandle)
        }
      })
    }, [])

    React.useEffect(() => {
      if(activeCart) handleClose()
    }, [activeCart])

    return (
      <div>
        <Script>
          {`
        !function(a,b,c,d,e,f,g){
          var h=d+"app/redpepper/redpepper.min.js",
          i=b.createElement(c),
          j=b.getElementsByTagName(c)[0],
          k=function(){
            redpepper.init(d,"red-pepper-catalog",e,f,g)
          };
          i.async=1,
          i.onload=k, i.src=h,j.parentNode.insertBefore(i,j)
        }(window,document,"script","https://app.redpepperdigital.net/",${catalogueId} );
      `}
        </Script>
        <div id="red-pepper-catalog" />
        <Modal toggleModal={!!quickViewProduct} onClose={handleClose}>
          <ModalContentsWithLayout layout="quickView">
            {quickViewProduct && (
              <QuickViewContent
                handle={quickViewProduct}
                parentProductHandle={quickViewProductParentHandle}
              />
            )}
          </ModalContentsWithLayout>
        </Modal>
      </div>
    )
  },
)

export default RedPepperCatalogueEmbed
